import { useEffect } from "react";
import { Outlet, Navigate } from "react-router-dom";
import axiosRequest from "../axiosRequest";

const PrivateRoute = () => {
  const loggedIn = localStorage.getItem("token");
  useEffect(() => {
    if (loggedIn) {
      axiosRequest("login/validateToken", "POST")
    }
  }, [])    
  return loggedIn ? <Outlet /> : <Navigate to="/login" />;
};
export default PrivateRoute;